* {
  font-family: "Shabnam" !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Shabnam";
  direction: rtl;
  background: #f5f5f5;
}

.header {
  color: #fff;
}

.datepicker-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px;
  background: #fff;
  height: 300px;
  border-radius: 0 0 5px 5px;
  direction: ltr;
}

.ql-editor {
  direction: rtl;
  text-align: right !important;
  line-height: 30px !important;
}

.img-responsive {
  max-width: 100%;
}

.logo {
  margin: 15px 10px;
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  color: #fff;
  padding: 10px 2px;
  font-size: 12px;
  border-radius: 10px;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.ant-menu-item {
  border-bottom: 1px solid #ccc;
}

.ant-menu-item:hover {
  background-color: #829ead !important;
}

.ant-modal .ant-modal-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  padding: 15px 10px;
  background-color: #eee;
}

.ant-modal .ant-modal-footer {
  border-top: 1px solid #eee;
  padding-top: 10px;
  padding: 15px 10px;
  background-color: #eee;
}

.ant-modal .ant-modal-content {
  padding: 0 !important;
}

.ant-modal .ant-modal-body {
  padding: 15px 10px;
  max-height: 400px;
    overflow-x: auto;
}

.panel-search {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.order {
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px #eee;
}

.order-item {
  margin: 2px 0;
  border-bottom: 1px solid #eee;
}

.product-detail-price {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
}

.product-detail-price span {
  margin: 0 3px;
}

.product-detail-priceWithDiscount {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
}

.product-detail-priceWithDiscount del {
  color: #666;
  margin: 0 3px;
}

.product-detail-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #ed2c2c;
  border-radius: 10px;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .panel-search {
    flex-direction: column;
  }
}