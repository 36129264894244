/* IRANSans */

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: bold;
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Bold.eot');
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./IRANSans4/WebFonts/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('./IRANSans4/WebFonts/fonts/woff/IRANSansWeb_Bold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./IRANSans4/WebFonts/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 500;
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Medium.eot');
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./IRANSans4/WebFonts/fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('./IRANSans4/WebFonts/fonts/woff/IRANSansWeb_Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./IRANSans4/WebFonts/fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 300;
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Light.eot');
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./IRANSans4/WebFonts/fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('./IRANSans4/WebFonts/fonts/woff/IRANSansWeb_Light.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./IRANSans4/WebFonts/fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
}

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 200;
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_UltraLight.eot');
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./IRANSans4/WebFonts/fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('./IRANSans4/WebFonts/fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./IRANSans4/WebFonts/fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb.eot');
    src: url('./IRANSans4/WebFonts/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
    url('./IRANSans4/WebFonts/fonts/woff2/IRANSansWeb.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
    url('./IRANSans4/WebFonts/fonts/woff/IRANSansWeb.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./IRANSans4/WebFonts/fonts/ttf/IRANSansWeb.ttf') format('truetype');
}

@font-face {
  font-family: "Shabnam";
  src: url("./shabnam/Shabnam-FD.eot");
  src: url("./shabnam/Shabnam-FD.eot") format("embedded-opentype"), url("./shabnam/Shabnam-FD.woff") format("truetype"), url("./shabnam/Shabnam-FD.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
